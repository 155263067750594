<template>
  <div class="mt-1 flex justify-space-between align-center sm-direction-column">
    <div class="left">
      <h1 class="color-secondary ">Puntos Cashback</h1>
      <h2 class="mb-0 color-primary">$200.00 MXN</h2>
      <p class="mt-0 alt color-40">Acumulados</p>
      <h6 class="mt-0 flex align-center color-20">
        <svg class="mr-1" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.75 7.25H3.25V8.75H4.75V7.25ZM7.75 7.25H6.25V8.75H7.75V7.25ZM10.75 7.25H9.25V8.75H10.75V7.25ZM12.25 2H11.5V0.5H10V2H4V0.5H2.5V2H1.75C0.9175 2 0.2575 2.675 0.2575 3.5L0.25 14C0.25 14.825 0.9175 15.5 1.75 15.5H12.25C13.075 15.5 13.75 14.825 13.75 14V3.5C13.75 2.675 13.075 2 12.25 2ZM12.25 14H1.75V5.75H12.25V14Z"
            fill="#ACACAC"/>
        </svg>
        Vigencia: 31 de diciembre 2022
      </h6>
    </div>
    <div class="right">
      <img src="https://via.placeholder.com/300x300?text=adomicilio1" alt="Adomicilio">
    </div>
  </div>
  <div class="mt-5">
    <div class="item">
      <hr class="bg-color-20">
      <h5 class="color-primary mb-0">¿Qué son los puntos Cashback?</h5>
      <p class="alt">Es un programa de lealtad que brinda el beneficio de retorno de efectivo por las compras
        realizadas dentro de nuestro restaurante.</p>
    </div>
    <div class="item mt-4">
      <hr class="bg-color-20">
      <h5 class="color-primary mb-0">¿Cómo ganas puntos Cashback?</h5>
      <p class="alt">Por cada compra se te regresa un porcentaje de la misma en un monedero electrónico,
        mismo que puedes utilizar 24 horas después en tu siguiente compra.</p>
    </div>
  </div>
</template>
<style lang="scss">
.item {
  p {
    max-width: 640px;
  }
}
</style>
